/*
 *  TODO
 *   此处添加项目logo
 *   public添加项目logo
 *   全局搜索【项目名称】替换项目名称
 * */
import api from '@/utils/http/axiosKeyConfig';
import { useUserStore } from '@/store/modules/user';

export const baseImgUrl = {
  baseImgUrl: '',
  jointUrl: (url: any) => {
    const { ossURL } = useUserStore();
    if (url == undefined || url == '' || url == null || url == 'null' || url == 'undefined') {
      return api.logo;
    } else if (url.indexOf('http') > -1) {
      return url;
    } else {
      return ossURL + url;
    }
  },
};

export const riFormType = {
  addressSelect: 'riAddressSelect',
  input: 'riInput',
  select: 'riSelect',
  checkBox: 'checkBox',
};

export const requestParameters = {
  // 请求参数
  request_SUCCESS: 0, // 请求成功状态
  request_FAIL: 1, // 请求失败状态
  request_TOKEN_ERROR: 401, // 登录失效
  request_SIGN_ERROR: 402, // 签名校验失败
  request_AUTH_ERROR: 403, // 访问受限
  request_RUN_EXCEPTION: -1, // 主动抛出异常（一般为参数校验失败，或流程校验不通过等）
  request_BLACK: -10, // 黑名单
  request_NOT_PERMISSION: -20, // 无权限访问
  request_TIMEOUT: -30, // 请求超时
  request_VISIT_OUT: -40, // 访问次数受限
  request_NOT_FOUND: 404, // 资源不存在
  request_NOT_REPLENISH: 800, // 补充信息未填写
};

/*
 * 埋点信息
 * */
export const CountType = {
  web_page: 'web_page', //	web页面统计链接
  web_login_action: 'web_login_action', //	web登录相关事件统计链接
  action: 'action', // 事件统计链接
  product: 'product', // 产品相关统计链接
  operate: 'operate', // 运营位相关统计
};
export const WebPage = {
  loginPage: 'loginPage', //	登录页
  remainMeans1: 'remainMeans1', //	留资页1
  remainMeans2: 'remainMeans2', //	留资页2
  remainMeans3: 'remainMeans3', //	留资页3
  refereeProduct: 'refereeProduct', //	产品推荐页
  refereeApiLoan: 'refereeApiLoan', //	api推荐页
  refereeOnlineLoan: 'refereeOnlineLoan', //	网贷推荐页
  failReferee: 'failReferee', //	推荐失败页
  successReferee: 'successReferee', //	推荐成功页
  loanHome: 'loanHome', //	贷超首页
  secondaryRecommend: 'secondaryRecommend', //	二次推荐页面展示
  authVisit: 'authVisit', // 授权页访问
  authClick: 'authClick', // 授权点击
  refereeProductAuth: 'refereeProductAuth', // 助贷推荐
  refereeApiLoanAuth: 'refereeApiLoanAuth', // api推荐
};
export const WebLoginAction = {
  keyPhone: 'keyPhone', //	落地页手机号输入	手机号文本框键入
  checkCodeButton: 'checkCodeButton', //	落地页验证码获取按钮
  checkCode: 'checkCode', //	落地页验证码输入	验证码文本框键入
  loginButton: 'loginButton', //	落地页登录按钮
  tokenLoginSuccess: 'tokenLoginSuccess', //	静默登录成功
};
export const Action = {
  callback: 'callback', //	返回拦截弹窗	前端埋点
  callbackSure: 'callbackSure', //	返回拦截同意	前端埋点
  callbackGiveUp: 'callbackGiveup', //	返回拦截拒绝	前端埋点
  productApply: 'productApply', //	产品申请	前端埋点
  applySuccess: 'applySuccess', //	产品申请同意	前端埋点
  applySuccessGiveUp: 'applySuccessGiveup', //	产品申请拒绝	前端埋点
  secondaryRecommendGiveUp: 'secondaryRecommendGiveUp', //	二次推荐页面放弃申请
  secondaryRecommendApply: 'secondaryRecommendApply', //	二次推荐页面点击申请
  linkInfoPageShow: 'linkInfoPageShow', //	机构助贷经理联系方式页访问
  linkInfoPageWechatClick: 'linkInfoPageWechatClick', //	助贷经理联系方式页点击微信
  updateCityClick: 'updateCityClick', //	结果页修改城市埋点
};
export const Product = {
  show: 'show', // 产品展示
  click: 'click', // 点击
};

/* 公用的年利率 , 1万利息 , 1千利息 */
export const commonInterestRate = {
  interest: 7.2,
  dayInterest: 0.02,
  rate: '2',
  rate_thousand: '0.2',
};

/* 部分百家姓（不全） */
export const familyName = [
  '李',
  '王',
  '张',
  '刘',
  '陈',
  '杨',
  '赵',
  '黄',
  '周',
  '吴',
  '徐',
  '孙',
  '胡',
  '朱',
  '高',
  '林',
  '何',
  '郭',
  '马',
  '罗',
  '梁',
  '宋',
  '郑',
  '谢',
  '韩',
  '唐',
  '冯',
  '于',
  '董',
  '萧',
  '程',
  '曹',
  '袁',
  '邓',
  '许',
  '傅',
  '沈',
  '曾',
  '彭',
  '吕',
  '苏',
  '卢',
  '蒋',
  '蔡',
  '贾',
  '丁',
  '魏',
  '薛',
  '叶',
  '阎',
  '余',
  '潘',
  '杜',
  '戴',
  '夏',
  '钟',
  '汪',
  '田',
  '任',
  '姜',
  '范',
  '方',
  '石',
  '姚',
  '谭',
  '廖',
  '邹',
  '熊',
  '金',
  '陆',
  '郝',
  '孔',
  '白',
  '崔',
  '康',
  '毛',
  '邱',
  '秦',
  '江',
  '史',
  '顾',
  '侯',
  '邵',
  '孟',
  '龙',
  '万',
  '段',
  '雷',
  '钱',
  '汤',
  '尹',
  '黎',
  '易',
  '常',
  '武',
  '乔',
  '贺',
  '赖',
  '龚',
  '文',
  '庞',
  '樊',
  '兰',
  '殷',
  '施',
  '陶',
  '洪',
  '翟',
  '安',
  '颜',
  '倪',
  '严',
  '牛',
  '温',
  '芦',
  '季',
  '俞',
  '章',
  '鲁',
  '葛',
  '伍',
  '韦',
  '申',
  '尤',
  '毕',
  '聂',
  '丛',
  '焦',
  '向',
  '柳',
  '邢',
  '路',
  '岳',
  '齐',
  '沿',
  '梅',
  '莫',
  '庄',
  '辛',
  '管',
  '祝',
  '左',
  '涂',
  '谷',
  '祁',
  '时',
  '舒',
  '耿',
  '牟',
  '卜',
  '肖',
  '詹',
  '关',
  '苗',
  '凌',
  '费',
  '纪',
  '靳',
  '盛',
  '童',
  '欧',
  '甄',
  '项',
  '曲',
  '成',
  '游',
  '阳',
  '裴',
  '席',
  '卫',
  '查',
  '屈',
  '鲍',
  '位',
  '覃',
  '霍',
  '翁',
  '隋',
  '植',
  '甘',
  '景',
  '薄',
  '单',
  '包',
  '司',
  '柏',
  '宁',
  '柯',
  '阮',
  '桂',
];
