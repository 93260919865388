export default [
  {
    path: '/LvpageLoginThree',
    name: 'LvpageLoginThree',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageThree/login.vue'),
  },
];
