import { threeElementsCapital } from '@/api/retainCapital';
import http from '@/utils/http';

export interface BasePromise {
  code: number;
  data: any;
  message: string;
}

export interface LoginWithThreeElementsCapital {
  token: string;
  register: number;
  userDetail: UserDetail;
}

export interface UserDetail {
  id: number;
  uuid: string;
  name: string;
  idCard: string;
  userPhone: string;
  channelId: number;
  isAppActive: number;
  activeTime: string;
  webActive: number;
  webActiveTime: string;
  userType: number;
  createTime: string;
  certificationStatus: number;
  completeDataStatus: number;
  completeDataTime: string;
  city: string;
  locationCity: string;
  logOff: number;
  isCapital: number;
  isOcr: number;
  isPureEdition: number;
  certificationStep: number;
  idCardExpiration: number;
}

interface ChannelDetail {
  isAgreement: number;
  isH5Gps?: number;
  status?: number;
}
export const channelDetail = (): Promise<ChannelDetail> =>
  http.get<ChannelDetail>(`/customer/channel/detail.e`);

export interface OperateConfig {
  id: number;
  name: string;
  type: number;
  location: number;
  sort: number;
  status: number;
  osDisplay: number;
  title: string;
  descriptor: string;
  jumpType: number;
  jumpUrl: string;
  isSplitJoint: number;
  logo: string;
  platformLimitType: number;
  platformStr: string;
  channelLimitType: number;
  channelListStr: string;
}

export const operateConfig = (data: {
  type: number;
  location: number;
  showPlatform: number;
}): Promise<OperateConfig[]> => http.get<OperateConfig[]>(`/customer/operateConfig/query.e`, data);

interface ChannelCheck {
  status: number;
  registerStatus: number;
  channelCheck: number;
}
export const channelCheck = (data: { id: string; registerPageId: string }): Promise<ChannelCheck> =>
  http.get<ChannelCheck>(`/customer/channel/check.e`, data);

// 落地页未匹配到产品触发营销
export const pageNotMatchProduct = (): Promise<ChannelCheck> =>
  http.get<ChannelCheck>(`/customer/pageNotMatchProduct`);

interface GpsLocation {
  adcode?: string;
  city: string;
  citycode: string;
  country: string;
  district: string;
  province: string;
  [key: string]: any;
}
// 通过经纬度获取城市
export const getGpsCity = (data: { lat: number; lng: number }): Promise<GpsLocation> =>
  http.get<GpsLocation>(`/customer/regeo.e`, data);
