export default [
  {
    path: '/LvpageLoginOne',
    name: 'pageLoginOne',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageOne/login.vue'),
  },
  {
    path: '/LvpageLeaveInfoOne',
    name: 'pageLeaveInfoOne',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageOne/leaveInfo.vue'),
  },
  {
    path: '/LvpageResultOne',
    name: 'pageResultOne',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageOne/result.vue'),
  },
  {
    path: '/LvpageLoadingOne',
    name: 'pageLoadingOne',
    component: () => import('@/pages/pageOne/loading.vue'),
    meta: {
      title: '额度检测',
    },
  },
  {
    path: '/LvpageSimpleLeaveInfoOne',
    name: 'pageSimpleLeaveInfoOne',
    component: () => import('@/pages/pageOne/simpleLeaveInfo.vue'),
    meta: {
      title: '申请资料',
    },
  },
];
