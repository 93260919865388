export default [
  {
    path: '/LvpageLoginTwo',
    name: 'pageLoginTwo',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageTwo/login.vue'),
  },
  {
    path: '/LvpageTwoStepOne',
    name: 'LvpageTwoStepOne',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepOne.vue'),
  },
  {
    path: '/LvpageTwoStepTwo',
    name: 'LvpageTwoStepTwo',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepTwo.vue'),
  },
  {
    path: '/LvpageTwoStepThree',
    name: 'LvpageTwoStepThree',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepThree.vue'),
  },
  {
    path: '/LvpageTwoStepFour',
    name: 'LvpageTwoStepFour',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepFour.vue'),
  },
  {
    path: '/LvpageTwoStepFive',
    name: 'LvpageTwoStepFive',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepFive.vue'),
  },
  {
    path: '/LvpageTwoStepSix',
    name: 'LvpageTwoStepSix',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepSix.vue'),
  },
  {
    path: '/LvpageTwoStepSeven',
    name: 'LvpageTwoStepSeven',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepSeven.vue'),
  },
  {
    path: '/LvpageTwoStepEight',
    name: 'LvpageTwoStepEight',
    meta: {
      title: '申请借款',
    },
    component: () => import('@/pages/pageTwo/stepEight.vue'),
  },
  {
    path: '/LvpageResultTwo',
    name: 'pageResultTwo',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageTwo/result.vue'),
  },
  {
    path: '/LvpageLoadingTwo',
    name: 'pageLoadingTwo',
    component: () => import('@/pages/pageTwo/loading.vue'),
    meta: {
      title: '额度检测',
    },
  },
  {
    path: '/LvpageSimpleLeaveInfoTwo',
    name: 'pageSimpleLeaveInfoTwo',
    component: () => import('@/pages/pageTwo/simpleLeaveInfo.vue'),
    meta: {
      title: '申请资料',
    },
  },
];
