<template>
  <van-overlay :show="requestLoading" @click.stop class="pageLoading">
    <van-loading size="48px" color="#1989fa" vertical>{{
      requestLoadingText ? requestLoadingText : '数据加载中...'
    }}</van-loading>
  </van-overlay>
  <router-view />

  <GoBackModal ref="goBackModal" :operateData="operateData"></GoBackModal>
</template>

<script setup lang="ts">
  import copyPaste from '@/utils/lib/copy-paste';
  import { storeToRefs } from 'pinia';
  import { useMyLoading } from '@/store/modules/loading';
  import GoBackModal from '@/components/routerLeavePop/loginBackModal.vue';
  import { backModelMethods } from '@/hooks/useBackModel';
  import { operateConfig, OperateConfig } from '@/api/base';

  const goBackModal = ref<any>(null);
  const { doGoBack, isGlobalGoBackList } = backModelMethods();
  const useMyLoading_ = useMyLoading();
  const { requestLoading, requestLoadingText } = storeToRefs(useMyLoading_);

  const router = useRouter();
  onMounted(async () => {
    if (['pro', 'pro_qh'].includes(import.meta.env.VITE_USER_NODE_ENV)) {
      /**不可粘贴*/
      copyPaste.disable();
    }
  });
  onBeforeUnmount(() => {
    if (['pro', 'pro_qh'].includes(import.meta.env.VITE_USER_NODE_ENV)) {
      /**不可粘贴*/
      copyPaste.enable();
    }
  });

  const operateData = ref<OperateConfig>();
  const fn = () => {
    doGoBack(goBackModal);
  };
  watch(
    () => router.currentRoute.value.path,
    (val, oldValue) => {
      if (val.includes('LvpageLogin') && !isGlobalGoBackList.value.includes(val)) {
        operateConfig({
          showPlatform: 1,
          type: 2,
          location: 6,
        }).then((res: OperateConfig[]) => {
          if (res && res?.length) {
            history.pushState(null, '', document.URL);
            window.addEventListener('popstate', fn, false);
            operateData.value = res[0];
          }
        });
      }
      if (oldValue.includes('LvpageLogin')) window.removeEventListener('popstate', fn, false);
    }
  );
</script>

<style>
  #app {
    font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  * {
    margin: 0;
    padding: 0;
  }
  .pageLoading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
