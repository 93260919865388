import http from '@/utils/http';

interface Point {
  countType: string;
  code: string;
  param?: {
    source?: string;
    productId?: number;
    productIds?: string;
    operateId?: number;
    phoneMd5?: string;
    fromSource?: string;
  };
}

// 埋点
export const doPoint = (data: Point, step?: number): Promise<any> => {
  if (
    (!step || step === 1) &&
    data.countType === 'web_page' &&
    (data.code === 'refereeOnlineLoan' ||
      data.code === 'refereeApiLoan' ||
      data.code === 'refereeProduct')
  ) {
    data.param = {
      ...data.param,
      ...{ source: 'web_remain_referee' },
    };
  }
  return http.post<any>(`/bury/count.e`, data);
};

interface Agreement {
  agreementId: number;
  loanProductId?: number;
}
// 埋点
export const signAgreement = (params: Agreement): Promise<any> =>
  http.get<any>(`/customer/signAgreementNew`, params);

// e签宝电子签章
export const signature = (params: {
  loanProductId: number;
  agreementName: string;
  agreementId: number;
  otherAgencyName?: string;
  templateNo?: string;
}): Promise<any> => http.post<any>(`/customer/signatureNew`, params);

/**
 * 贷款方案页埋点
 * productId 申请产品id
 * type 记录类型 0-页面进入，1-点击拨打电话，2-点击客服微信
 */
export const linkInfoRecord = (params: { productId: string; type: number }): Promise<any> =>
  http.post<any>(`/product/linkInfo/record`, params);

/**
 * 获取留资页UV申请成功数量
 */
export const successNumber = (): Promise<any> =>
  http.get<any>(`/customer/user/capital/successNumber`);
