import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { pageRouter } from '@/router/pageRouter';
import { storage } from '@/utils/storage';
import { useUserStore } from '@/store/modules/user';
import api from '@/utils/http/axiosKeyConfig';
import axios from '@/utils/http/axios';
import { AgreementResultData, editCity, ipAddr, userAgreementResult } from '@/api/login';
import { channelDetail, getGpsCity } from '@/api/base';
import { loginFilter } from '@/api/pageLoading';
import { mainStore } from '@/store/modules/common/common';

const routes: Array<RouteRecordRaw> = [
  ...pageRouter,
  // 替代vue2中的'*'通配符路径
  { path: '/:pathMatch(.*)*', redirect: '/404' },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(), // hash 模式则使用 createWebHashHistory()
  routes,
});

router.afterEach((to, from) => {
  /* 路由发生变化修改页面 title */
  if (to?.meta?.title) document.title = String(to.meta.title);
  if (to.path === '/') {
    router.go(-1);
  }
});
router.beforeEach(async (to, from, next) => {
  if (to?.path?.indexOf('/LvpageLogin') > -1 && to?.path?.indexOf('/LvpageToken') === -1)
    storage.setItem('localUrl', to.path, null, 2);
  const query = to.query;
  for (const i in query) {
    saveRouterValue(i, <string>query[i]);
  }

  /**
   * 此处记录osType  用于判断是否在APP内
   * 在APP首次进入二次推荐页时 osType不是 h5
   */
  if (storage.getItem('osType', 2) && storage.getItem('osType', 2) !== 'h5') {
    storage.setItem('localOsType', storage.getItem('osType', 2), null, 2);
  }

  const { setCity, agreementURLArr, setWapHomeUrl, wapHomeUrl } = useUserStore();

  // 获取wap端访问域名
  if (wapHomeUrl === '') {
    axios.get(api.ossPath + `/${api.BODY_CODE}` + '/60').then((res) => {
      if (res?.data?.url) setWapHomeUrl(res?.data?.url);
    });
  }

  if (!/404/.test(window.location.href)) {
    // 留资页、首次推荐页增加根据渠道H5GPS配置获取GPS城市定位
    const routPathArr = ['/LvpageLeaveInfo', '/LvpageResult'];
    let flag = false;
    routPathArr.forEach((item: string) => {
      if (to.path.includes(item)) {
        flag = true;
      }
    });
    if (flag && !storage.getItem('localGPSCity', 2)) {
      const res = await channelDetail();
      console.log('渠道详情', res);
      if (res?.isH5Gps === 1) {
        /* 开启h5GPS定位 */
        try {
          getGpsLocation().then((locRes: any) => {
            if (locRes) {
              getGpsCity({ lat: locRes.latitude, lng: locRes.longitude }).then((gpsRes) => {
                console.log('城市信息', gpsRes);
                if (gpsRes?.city) {
                  storage.setItem('localCity', gpsRes.city, null, 2);
                  storage.setItem('localGPSCity', gpsRes.city, null, 2);
                  if (storage.getItem('cityChoose', 2) === null) {
                    storage.setItem('cityChoose', gpsRes.city, null, 2);
                  }
                  const store = mainStore();
                  store.setUserCityGps(gpsRes.city);
                  /* 修改城市 */
                  editCity({
                    city: gpsRes.city as string,
                    locationCity: gpsRes.city as string,
                  }).then((res) => {
                    if (!res) loginFilter();
                  });
                }
              });
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    if (storage.getItem('localCity', 2) === null) {
      await ipAddr().then((res) => {
        if (!res) return;
        storage.setItem('localCity', res.cityName, null, 2);
        if (storage.getItem('cityChoose', 2) === null) {
          storage.setItem('cityChoose', res.cityName, null, 2);
        }
        setCity(res.cityName);
      });
    }
  }

  if (!agreementURLArr) {
    const agreementRes: AgreementResultData = await userAgreementResult({ packageType: 10000 });
    if (agreementRes) {
      const store = mainStore();
      store.setAgreementURL(agreementRes);
    }
  }

  next();
});
function saveRouterValue(name: string, value: string) {
  if (name) {
    if (name === 'commToken') {
      name = 'token';
    }
    if (name === 'token') {
      storage.setItem(name, value, null, 1);
    } else {
      storage.setItem(name, value, null, 2);
    }
  }
}

/* GPS定位 */
function getGpsLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (success) => {
          const { latitude, longitude } = success.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error);
        },
        {
          enableHighAccuracy: false,
          maximumAge: 0,
        }
      );
    } else {
      reject();
    }
  });
}

export default router;
