import { defineStore } from 'pinia';
import { storage } from '@/utils/storage';
import { AgreementResultData } from '@/api/login';

export const mainStore = defineStore('main', {
  state: () => {
    return {
      userCityGps: storage.getItem('localGPSCity') || '', // GPS城市
      agreementURLArr: {} as AgreementResultData,
    };
  },

  getters: {
    getUserCityGps(state: any) {
      return state.userCityGps as string;
    },
    getAgreementURLArr(state: any) {
      return state.agreementURLArr;
    },
  },

  actions: {
    setUserCityGps(city: string) {
      this.userCityGps = city;
    },
    setAgreementURL(url: AgreementResultData) {
      this.agreementURLArr = url;
    },
  },
});
