import http from '@/utils/http';
import { propsList } from '@/components/pageResult/resultType';

export interface Data {
  records: Records[];
  total: number;
  size: number;
  current: number;
}
export interface Records {
  id: number;
  status: number;
  ifHide: number;
  type: number;
  category: number;
  loanProductName: string;
  companyName: string;
  agencyId: number;
  paymentRate: string;
  label: string;
  avgAmount: number;
  productType: number;
  minLoanAmount: number;
  maxLoanAmount: number;
  minLoanTerm: number;
  maxLoanTerm: number;
  monthlyRate: number;
  logo: string;
  price: number;
  sortPrice: number;
  sort: number;
  minAge: number;
  maxAge: number;
  applyCondition: string;
  auditRemark: string;
  productRemark: string;
  shelfCycle: number;
  onlineTime: string;
  offlineTime: string;
  applyNum: number;
  hasApplyNum: number;
  openDetail: number;
  osDisplay: number;
  matchingUrl: string;
  registerUrl: string;
  queryOrderUrl: string;
  code: string;
  onlinePeriod: string;
  protocolType: number;
  productProtocolName: string;
  productProtocolUrl: string;
  limitCityType: number;
  limitCity: string;
  limitChannelType: number;
  channelGroupIdStr: string;
  channelIdStr: string;
  updateTime: string;
  loanProductCapitalResponse: LoanProductCapitalResponse;
  isCityMatching: number;
  isApply: number;
}
export interface LoanProductCapitalResponse {
  id: number;
  loanProductId: number;
  nullParam: number;
  filterType: number;
  minAge: number;
  maxAge: number;
  phoneSegment: string;
  qualification: string;
  profession: string;
  gjj: string;
  socialInsurance: string;
  house: string;
  car: string;
  insurancePolicy: string;
  sesame: string;
  workAge: string;
  payType: string;
  monthlyIncome: string;
  creditCard: string;
  creditInformation: string;
  limitCityType: number;
  limitCity: string;
}

// 获取产品列表
// 后台配置匹配产品
export const getOrderResultPage = (data: { current?: number; size?: number }): Promise<any> =>
  http.get<any>('/product/capitalRecommend', data);
// 只有助贷产品
export const getOrderResultPage2 = (data: { current: number; size: number }): Promise<any> =>
  http.get<any>('/product/capitalRecommend2', data);
// 全部产品
export const getOrderResultPage3 = (data: { current?: number; size?: number }): Promise<any> =>
  http.get<any>('/product/capitalRecommend3', data);
// 只匹配信贷api
export const getOrderResultPage4 = (data: {
  current?: number;
  size?: number;
  applySource?: string;
}): Promise<any> => http.get<any>('/product/capitalRecommend4', data);
// 只匹配普通网贷和联登网贷
export const getProductRecommend = (data: { current?: number; size?: number }): Promise<any> =>
  http.get<any>('/product/h5/recommend', data);
// 全部网贷产品
export const getOrderResultPage5 = (data: { current?: number; size?: number }): Promise<any> =>
  http.get<any>('/product/capitalRecommend5', data);
// 全部产品  只匹配一个助贷在第二个位置
export const getOrderResultPage6 = (data: {
  current?: number;
  size?: number;
  applySource?: string;
}): Promise<any> => http.get<any>('/product/capitalRecommend6', data);

// 网贷申请
export const productApply = (data: { id: number; applySource?: string }): Promise<any> =>
  http.get<any>('/product/apply', data);
// 网贷申请(带补充信息)
export const productApplyNew = (data: {
  id: number;
  applySource?: string;
  capitalReplenishList?: any;
}): Promise<any> => http.post<any>('/product/applyNew', data);
// 网贷申请前先传补充信息
export const applyReplenish = (data: { id: number; capitalReplenishList?: any }): Promise<any> =>
  http.post<any>('/product/applyReplenish', data);

// 银行贷申请
export const productAidApply = (data: { id: number; applySource?: string }): Promise<any> =>
  http.get<any>('/product/aidApply', data);

export const wapHomePageRecommend = (data: { current: number; size: number }): Promise<Data> =>
  http.get<Data>(`/product/wap/homePageRecommend/100`, data);

// 产品详情查询
export const productDetail = (data: { id: number | string }): Promise<any> =>
  http.get<any>('/product/detail', data);

// 普通网贷和联登网贷
export const returnIntercept = (data: { current: number; size: number }): Promise<Data> =>
  http.get<Data>(`/product/returnIntercept`, data);

// api表单提交回传
export const otherCallBack = (data: {
  supplier: number;
  pointType: number;
  callBackType: number;
  landingPageUrl: string;
}): Promise<Data> => http.post<Data>(`/customer/otherCallBack`, data);

// 标记查询风险报告
export const reportReceiveReceive = (data?: { code: string }) => {
  http.get(`/customer/reportReceive/receive`, data);
};

// 是否获取历史风险报告
export const reportReceiveIsChecked = (): Promise<boolean> =>
  http.get<boolean>(`/customer/reportReceive/isChecked`);

// 授权页贷款产品信息查询
export const callbackProductApplyDetail = (data: { orderId: string }): Promise<propsList> =>
  http.post<propsList>(`/open/callback/product/applyDetail`, data);
// 授权页贷款产品确认
export const callbackProductApply = (data: { orderId: string }): Promise<any> =>
  http.post<any>(`/open/callback/product/apply`, data);

// 撞库
export const loginFilter = (): Promise<any> => http.get<any>('/customer/loginFilter');
