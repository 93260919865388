export default [
  {
    path: '/DlPageHxOneLogin',
    name: 'DlPageHxOneLogin',
    meta: {
      title: '下载页',
    },
    component: () => import('@/pages/pageDl/pageOne/login.vue'),
  },
];
