import pinia from '@/store';
import App from './App.vue';
import router from '@/router';
import 'amfe-flexible';
import 'vant/lib/index.css';
import '@/styles/index.less';
import Vconsole from 'vconsole';
import { storage } from '@/utils/storage';

if (import.meta.env.VITE_USER_NODE_ENV === 'test' || storage.getItem('debug', 2) === '1') {
  new Vconsole();
}
const app = createApp(App);
app.use(router);
app.use(pinia);

app.mount('#app');
console.log(1);
