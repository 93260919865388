export default [
  {
    path: '/LvPageLoginFour',
    name: 'lvPageLoginFour',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageFour/login.vue'),
  },
  {
    path: '/LvPageLeaveInfoFour',
    name: 'lvPageLeaveInfoFour',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageFour/leaveInfo.vue'),
  },
  {
    path: '/LvPageResultFour',
    name: 'lvResultFour',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageFour/result.vue'),
  },
  {
    path: '/LvPageLoadingFour',
    name: 'lvLoadingFour',
    component: () => import('@/pages/pageFour/loading.vue'),
    meta: {
      title: '额度检测',
    },
  },
  {
    path: '/LvPageSimpleStepFour',
    name: 'lvSimpleStepFour',
    component: () => import('@/pages/pageFour/simpleLeaveInfo.vue'),
    meta: {
      title: '申请资料',
    },
  },
];
