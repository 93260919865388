import { RouteRecordRaw } from 'vue-router';
import pageOne from '@/router/routerList/one';
import pageTwo from '@/router/routerList/two';
import pageThree from '@/router/routerList/three'; // 优卡对接
import pageFour from '@/router/routerList/four';
import pageDl from '@/router/pageDl';

export const commonRouter = [
  {
    path: '/creditApiApplySuccess',
    name: 'creditApiApplySuccess',
    component: () => import('@/pages/pageCommon/creditApiApplySuccess.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/creditApiApplySuccessTwo',
    name: 'creditApiApplySuccessTwo',
    component: () => import('@/pages/pageCommon/creditApiApplySuccess2.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/creditApiApplyAgain',
    name: 'creditApiApplyAgain',
    component: () => import('@/pages/pageCommon/creditApiApplyAgain.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/creditApiApplyAgainTwo',
    name: 'creditApiApplyAgainTwo',
    component: () => import('@/pages/pageCommon/creditApiApplyAgain2.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/applySuccessCustomer',
    name: 'applySuccessCustomer',
    component: () => import('@/pages/pageCommon/applySuccessCustomer.vue'),
    meta: {
      title: '机构信息',
    },
  },
  {
    path: '/applySuccessNoCustomer',
    name: 'applySuccessNoCustomer',
    component: () => import('@/pages/pageCommon/applySuccessNoCustomer.vue'),
    meta: {
      title: '机构信息',
    },
  },
  {
    path: '/QRCode',
    name: 'QRCode',
    component: () => import('@/pages/pageCommon/QRCode.vue'),
    meta: {
      title: '机构信息',
    },
  },
];

export const pageRouter: Array<RouteRecordRaw> = [
  ...commonRouter,
  ...pageOne,
  ...pageTwo,
  ...pageThree,
  ...pageFour,
  ...pageDl,
];
