import logo from '@/assets/img/base/logo.png';

let ossPath = '';
let baseUrl = '';
let ossImgPath = '';
/*
 * TODO 需要添加或修改以下内容
 *  SIGN_KEY 签名
 *  BODY_CODE bodyCode 此处为默认值，URL上未获取到才使用的
 * */
const BODY_CODE = 'hxqb';
const SIGN_KEY = '8ku4jazkh2nkab7ccuyksuxssa3348uzsfdeupr3mr4yby2p2ptetr2wvfkxnaxc';

/*
 * TODO 需要添加或修改以下内容
 *  baseUrl 后台请求地址
 *  ossPath OSS动态域名地址
 *  ossImgPath OSS图片地址
 * */
switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'pro':
    baseUrl = 'https://gw.xiaoyiqb.cn';
    ossPath = 'https://ttxs-pro.oss-cn-chengdu.aliyuncs.com/open/domain';
    ossImgPath = 'https://oss.xiaoyiqb.cn';
    break;
  case 'test':
    baseUrl = 'https://test-gw.xiaoyiqb.cn';
    ossPath = 'https://ttxs-testing.oss-cn-chengdu.aliyuncs.com/open/domain';
    ossImgPath = 'https://test-oss.xiaoyiqb.cn';
    break;
  default:
    baseUrl = 'https://test-gw.xiaoyiqb.cn';
    ossPath = 'https://ttxs-testing.oss-cn-chengdu.aliyuncs.com/open/domain';
    ossImgPath = 'https://test-oss.xiaoyiqb.cn';
}
export default { logo, SIGN_KEY, ossPath, baseUrl, ossImgPath, BODY_CODE };
